import { useCache } from './useCache'
import { Immutable } from 'immer'

export interface UseBulkSelectResult {
  clearAll: () => void
  getSelectionState: GetSelectionState
  selectedItems: Immutable<string[]>
  setItems: (inputArray: string[]) => void
  toggleAll: ToggleAll
  toggleItem: (inputItem: string) => void
}

type ClearAll = () => void
type GetSelectionState = (
  availableCount: number
) => 'all' | 'indeterminate' | 'none'
type SetItems = (inputArray: string[]) => void
type ToggleAll = (availableItems: string[]) => void
type ToggleItem = (inputItem: string) => void

export interface BulkCache {
  selectedItems?: string[]
}

export default function useBulkSelect(namespace: string): UseBulkSelectResult {
  const { cache, setCache } = useCache<BulkCache>({ namespace })

  const clearAll: ClearAll = (): void => {
    setCache(cache => {
      cache.selectedItems = []
    })
  }

  const getSelectionState: GetSelectionState = availableCount => {
    return cache?.selectedItems?.length === availableCount
      ? 'all'
      : cache?.selectedItems?.length &&
        cache?.selectedItems?.length < availableCount
      ? 'indeterminate'
      : 'none'
  }

  const setItems: SetItems = itemsArray => {
    setCache(cache => {
      cache = cache || {}
      cache.selectedItems = itemsArray || []
      return cache
    })
  }

  const toggleItem: ToggleItem = (inputItem: string): void => {
    // if cache contains item, remove from cache, else add to cache
    setCache(cache => {
      cache = cache || {}
      cache.selectedItems = cache?.selectedItems || []
      if (cache.selectedItems.find(item => item === inputItem)) {
        cache.selectedItems = cache.selectedItems.filter(
          item => item !== inputItem
        )
        return cache
      }
      cache.selectedItems.push(inputItem)
      return cache
    })
    return
  }

  const toggleAll: ToggleAll = availableItems => {
    if (cache?.selectedItems?.length === availableItems.length) {
      return clearAll()
    }
    return setItems(availableItems)
  }

  return {
    clearAll,
    getSelectionState,
    selectedItems: cache?.selectedItems || [],
    setItems,
    toggleAll,
    toggleItem,
  }
}
