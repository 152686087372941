import React from 'react'
import Button from '../button'
import { Block, Text, Flex } from 'components/common'
import { useTranslation } from 'react-i18next'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import colors from 'config/theme/colors'

export const DeletePopupModal = NiceModal.create(props => {
  const { deleteModalMessage, deleteModalTitle } = props
  const { t } = useTranslation()
  const modal = useModal()

  return (
    <Block
      height="100%"
      width="100%"
      background="rgba(0,0,0, 0.5)"
      zIndex="9000"
      position="fixed"
    >
      <Block
        background="white"
        width="450px"
        height="fit-content"
        position="absolute"
        top="0"
        right="0"
        left="0"
        bottom="120px"
        borderRadius="5px"
        zIndex="9001"
        margin="auto"
      >
        <Block style={{ justifyContent: 'center' }}>
          <Block
            padding="5px 15px"
            height="auto"
            borderRadius="5px 5px 0px 0px"
            background={colors.red.normal}
          >
            <Text color="white" fontSize="20px" bold>
              {deleteModalTitle}
            </Text>
          </Block>

          <Block padding="15px" whiteSpace="pre-line">
            <Text marginTop={0}>{deleteModalMessage}</Text>
            <Flex>
              <Button
                onClick={() => {
                  modal.hide()
                  modal.remove()
                }}
                dataTestId="cancel-button"
              >
                {t('button.cancel')}
              </Button>
              <Button
                onClick={() => {
                  props.handleDelete()
                  modal.hide()
                  modal.remove()
                }}
                theme="negative"
                dataTestId="confirm-button"
              >
                {t('button.delete')}
              </Button>
            </Flex>
          </Block>
        </Block>
      </Block>
    </Block>
  )
})
