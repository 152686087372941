import React from 'react'
import Button from '../button'
import { Block, Text, Flex } from 'components/common'
import { useTranslation } from 'react-i18next'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import colors from 'config/theme/colors'

interface PopupModalProps {
  action: () => void
  actionText: string
  cancel?: () => void
  cancelText?: () => void
  showButtons?: boolean
  message: string | JSX.Element
}

export const PopupModal = NiceModal.create<PopupModalProps>(props => {
  const { 
    action, 
    actionText, 
    cancel, 
    showButtons = true,
    message, 
  } = props

  const { t } = useTranslation()
  const modal = useModal()

  const cancelText = props.cancelText || t('button.cancel')

  return (
    <Block
      height="100%"
      width="100%"
      background="rgba(0,0,0, 0.5)"
      zIndex="9000"
      position="fixed"
    >
      <Block
        background="white"
        width="450px"
        height="fit-content"
        position="absolute"
        inset={0}
        borderRadius="10px"
        zIndex="9001"
        margin="auto"
      >
        <Block justifyContent="center">
          <Block padding="24px" whiteSpace="pre-line">
            <Text marginTop={0}>{message}</Text>
            {showButtons ? (
              <Flex>
                <Button
                  onClick={() => {
                    console.debug('modal-response: ', cancelText)
                    if (cancel) {
                      cancel()
                    }

                    modal.resolve(cancelText)
                    modal.remove()
                  }}
                  dataTestId="cancel-button"
                >
                  {t('button.cancel')}
                </Button>
                <Button
                  onClick={() => {
                    console.debug('modal-response: ', actionText)
                    if (action) {
                      action()
                    }

                    modal.resolve(actionText)
                  }}
                  theme="primary"
                  dataTestId="action-button"
                >
                  {actionText}
                </Button>
              </Flex>
            ) : null}
          </Block>
        </Block>
      </Block>
    </Block>
  )
})
