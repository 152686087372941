import { Redirect, Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import React from 'react'
import { useFlags } from 'components/flags/hook'
import PageLoader from 'components/page-loader'

const Entry = Loadable({
  loader: () => import('./components/entry'),
  loading: PageLoader,
})

const DeprecatedList = Loadable({
  loader: () => import('./components/list-deprecated/index'),
  loading: PageLoader,
})
const List = Loadable({
  loader: () => import('./components/list'),
  loading: PageLoader,
})

export default function IssuesRoute() {
  const flags = useFlags()

  return (
    <Switch>
      <Route component={Entry} exact path="/reports/issues/:id" />
      {flags.rolloutBulkUpdateIssues ? (
        <Route component={List} exact path="/reports/issues" />
      ) : (
        <Route component={DeprecatedList} exact path="/reports/issues" />
      )}
      <Redirect to="/404" />
    </Switch>
  )
}
