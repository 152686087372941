import {
  attempt,
  get,
  isEmpty,
  isError,
  toNumber,
  find,
  isUndefined,
  map,
  omit,
  reduce,
  pick,
  round,
} from 'lodash'
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withPropsOnChange,
} from 'recompose'
import { connect } from 'react-redux'
import { getModule, validation } from '@lighthouse/sdk'
import {
  Field,
  getFormInitialValues,
  getFormSyncErrors,
  getFormValues,
  reduxForm,
} from 'redux-form'
import { point } from '@turf/helpers'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import React from 'react'

import { Block } from 'components/common'
import withUrlQuery from 'components/with-url-query'
import AuditTemplateEntry from './entry'
import { useModal } from '@ebay/nice-modal-react'
import { PopupModal } from 'components/popup-modal'
import Spinner from 'components/spinner'

import {
  BasicForm,
  FieldGroup,
  FieldSet,
  InputLocationSelect,
  InputText,
} from 'components/form'

import {
  getErrorCount,
  handleFormError,
  handleSaveResponse,
  handleDeleteResponse,
} from 'components/form/helpers'
import FlagsHOC from 'components/flags/hoc'

import { Crud } from 'components/controls'
import Alert from 'components/alert'
import ButtonGroup from 'components/button-group'
import colors from 'config/theme/colors'
import geocoder from 'utils/geocoder'
import logger from 'utils/logger'

import ControlWrapper from '../../../control-wrapper'
import FieldWrapper from '../../../field-wrapper'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { Trans } from 'react-i18next'

const LIST_ID = 'all'

const areaModule = getModule('areas')
const auditEntryModule = getModule('auditEntries')

const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined

export default compose(
  reduxForm(),
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['formValues'], calculateFormTotals),
  withRouter,
  withUrlQuery,
  withProps(buildFormProps),
  withHandlers({ deleteAuditEntry }),
  lifecycle({ componentDidUpdate }),
  FlagsHOC
)(Form)

function Form(props) {
  const {
    deleteAuditEntry,
    dirty,
    error,
    errorCount,
    flags,
    form,
    formValues,
    handleCancel,
    handleEdit,
    handleSubmit,
    history,
    id,
    initialValues,
    invalid,
    isEditing,
    readOnly,
    reset: handleReset,
    submitFailed,
    submitting,
    templateEntity,
    auditsCache,
    onClose,
    saveAuditEntry,
    userId,
  } = props

  const reviewFollowsUpModal = useModal(PopupModal)

  const { audit, targetServiceLevel } = initialValues

  const auditEntity = auditsCache[audit].entity || {}
  const template = pick(auditEntity, ['application', 'items', 'participants'])

  const hasTarget = targetServiceLevel === null ? false : true

  const { editFormTitles } = flags

  const isExistingAudit = !!id

  const { t } = useTranslation()

  // NOTE handles showing the follow up modal if follow ups have been triggered
  const handleConfirm = async () => {
    const items = parseItemValues(formValues, template)
    const followUpItems = items.reduce((acc, item) => {
      const scoreRequiredFollowUp = item.scores.find(
        score =>
          score.value === item.score &&
          score.requiredQuestionOptions.followUp === true
      )

      const followUpRequired =
        item.group.followUpsEnabled && scoreRequiredFollowUp
      if (followUpRequired || item.followUpTriggered === true) {
        acc.push(item)
      }
      return acc
    }, [])

    const followUpCount = followUpItems.length

    if (id || followUpCount === 0) {
      await handleSubmit(handleSave)()
      return onClose()
    }

    const confirmResponse = await reviewFollowsUpModal.show({
      // NOTE: the <Trans> component is required to handle the html tags in
      // the message
      message: (
        <Trans
          t={t}
          i18nKey="modal.submit.audit.message"
          count={followUpCount}
        />
      ),
      actionText: t('button.confirm'),
    })

    if (confirmResponse === t('button.confirm')) {
      reviewFollowsUpModal.show({
        message: (
          <Block padding="1.5em">
            <Spinner />
          </Block>
        ),
        showButtons: false,
      })

      const submitResponse = await handleSubmit(handleSave)()

      const reviewResponse = await reviewFollowsUpModal.show({
        actionText: t('button.review'),
        message: t('modal.review.audit.message'),
      })

      reviewFollowsUpModal.remove()

      // NOTE It's important to trigger onClose to force the form to destroy
      onClose()

      // user can select review to view submission or cancel to close side panel
      if (reviewResponse === t('button.review')) {
        history.push({
          search: queryString.stringify({
            resource: 'auditentries',
            showMarker: false,
            id: submitResponse._id,
          }),
        })
      }
    }
  }

  const handleSave = async values => {
    try {
      const { audit: auditId, title, footerFields, headerFields, gps } = values

      const items = parseItemValues(values, template)

      const payload = !id
        ? {
            ...template,
            date: new Date().toISOString(),
            footerFields,
            gps,
            headerFields,
            items,
            title,
            user: userId,
          }
        : {
            footerFields,
            gps,
            // NOTE: We have to unset location when editing in case new gps coordinates are not inside of
            // the previous location (legacy location id)
            location: null,
            headerFields,
            items,
            title,
          }

      const params = !id ? { auditId } : null
      const entity = await saveAuditEntry(params, payload, id)
      return handleSaveResponse('audit')(entity)
    } catch (error) {
      logger.error('AuditEntrySaveError', {
        err: error.message,
        stack: error.stack,
      })
      handleFormError(error)
    }
  }

  return (
    <BasicForm noValidate>
      <FieldWrapper>
        {error && submitFailed && (
          <Block paddingTop={10}>
            <Alert type="error" messages={[error]} />
          </Block>
        )}
        <FieldGroup>
          <FieldSet>
            <Field
              component={InputText}
              disabled={!editFormTitles}
              label={t('labelTitle')}
              name="title"
              placeholder={t('placeholder.auditTitle')}
              readOnly={readOnly}
              required
              small
              validate={[isRequired]}
            />
            {isExistingAudit && !isEditing && (
              <>
                <Field
                  component={InputText}
                  dataTestId="created-field"
                  label={t('labelCreated')}
                  name="created"
                  readOnly
                  small
                />
                <Field
                  component={InputText}
                  dataTestId="location-field"
                  label={t('labelLocation')}
                  name="location"
                  readOnly
                  small
                />
                {hasTarget ? (
                  <Field
                    component={InputText}
                    dataTestId="target-field"
                    label={t('labelTarget')}
                    name="target"
                    readOnly
                    small
                  />
                ) : null}

                <Field
                  component={InputText}
                  dataTestId="score-field"
                  label={t('labelScore')}
                  name="auditScore"
                  readOnly
                  small
                />
                {hasTarget ? (
                  <Field
                    component={InputText}
                    dataTestId="target-service-level-field"
                    label={t('labelServiceLevel')}
                    name="targetServiceLevel"
                    readOnly
                    small
                    style={{
                      color:
                        targetServiceLevel === 'Below Target'
                          ? colors.red.normal
                          : colors.green.normal,
                    }}
                  />
                ) : null}
              </>
            )}
            {!readOnly && (
              <Field
                component={InputLocationSelect}
                label={t('labelLocation')}
                mapTargetMode
                name="locationSelect"
                placeholder={t('placeholder.selectOrDropPin')}
                required
                resource="auditentries"
                small
                validate={[isRequired]}
              />
            )}
          </FieldSet>
        </FieldGroup>
        <FieldGroup>
          <AuditTemplateEntry
            form={form}
            id={id}
            isEditing={isEditing}
            readOnly={readOnly}
            small
            templateEntity={templateEntity}
            formValues={formValues}
            groupScores={initialValues.groupScores}
          />
        </FieldGroup>
      </FieldWrapper>
      <ControlWrapper>
        <ButtonGroup align="left">
          <Crud
            deleteModalMessage={t('modal.delete.audit.message')}
            deleteModalTitle={t('modal.delete.audit.title')}
            dirty={dirty}
            errorCount={errorCount}
            handleCancel={handleCancel}
            handleDelete={deleteAuditEntry}
            handleEdit={handleEdit}
            handleSave={handleConfirm}
            id={id}
            invalid={invalid}
            isEditing={isEditing}
            permissionModule="auditEntry"
            reset={handleReset}
            small
            submitting={submitting}
          />
        </ButtonGroup>
        <PopupModal id="reviewFollowUpModal" />
      </ControlWrapper>
    </BasicForm>
  )
}

function buildFormProps(props) {
  const { findEnclosing, formSyncErrors, formValues = {} } = props

  let availableFloors = []
  let enclosingBuilding = null

  const formGeometry = get(formValues.gps, 'geometry')

  if (formGeometry) {
    enclosingBuilding = findEnclosing(formGeometry, 'building')
    availableFloors = get(enclosingBuilding, 'entity.floors', [])
  }

  const errorCount = getErrorCount(formSyncErrors)

  return {
    availableFloors,
    enclosingBuilding,
    errorCount,
  }
}

async function componentDidUpdate(prevProps) {
  const {
    change,
    findEnclosing,
    formInitialValues,
    history,
    pristine,
    urlQuery,
  } = this.props

  const {
    enclosingBuilding: prevEnclosingBuilding,
    pristine: prevPristine,
    urlQuery: { lat: prevLat, lng: prevLng },
  } = prevProps

  const initialLat = get(formInitialValues, 'gps.geometry.coordinates.1')
  const initialLng = get(formInitialValues, 'gps.geometry.coordinates.0')

  // NOTE: the form has been reset, if we have existing gps values reset the
  // url query to reset the position of the icon on the map
  if (pristine && !prevPristine) {
    if (initialLat && initialLng) {
      const nextSearch = queryString.stringify({
        ...urlQuery,
        lat: initialLat,
        lng: initialLng,
      })

      history.push({ search: `?${nextSearch}` })
    } else {
      const nextQuery = omit(urlQuery, ['lat', 'lng'])
      const nextSearch = queryString.stringify(nextQuery)

      history.push({ search: `?${nextSearch}` })
    }

    return
  }

  const { lat, lng } = urlQuery

  const isInitialLat = lat === String(initialLat)
  const isInitialLng = lng === String(initialLng)

  // NOTE: prevent any further changes if form reset
  if (isInitialLat && isInitialLng) return

  const hasLatChanged = prevLat !== lat
  const hasLngChanged = prevLng !== lng

  if (!hasLatChanged && !hasLngChanged) return

  const lngLat = [toNumber(lng), toNumber(lat)]
  const geoJsonPoint = attempt(point, lngLat)
  const validPoint = !isError(geoJsonPoint)

  const geometry = validPoint ? geoJsonPoint.geometry : null

  if (!geometry) return

  const enclosingLocation = findEnclosing(geometry, 'location')
  const enclosingBuilding = findEnclosing(geometry, 'building')
  const availableFloors = get(enclosingBuilding, 'entity.floors', [])
  const hasChangedBuilding = prevEnclosingBuilding !== enclosingBuilding

  if (hasChangedBuilding || isEmpty(availableFloors)) {
    change('gps.floorsRef', [])
  }

  if (enclosingLocation) {
    // NOTE: we've moved to a new location
    const label = enclosingLocation.entity.name
    const value = enclosingLocation.id

    change('locationSelect', { label, value })
  } else {
    // NOTE: we've moved outside of a location
    const label = await getReverseGeoCoded({ lat, lng })
    const value = 'custom-value'

    change('locationSelect', { label, value })
  }

  change('gps.geometry', geometry)
}

function deleteAuditEntry(props) {
  const { deleteAuditEntry, id, onClose, setError, setDeleting } = props

  return () => {
    setDeleting(true)

    return deleteAuditEntry(id)
      .then(handleDeleteResponse('audit'))
      .then(onClose)
      .catch(error => setError(error))
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    deleteAuditEntry: id => dispatch(auditEntryModule.remove(id)),
    saveAuditEntry: (params, payload, id) =>
      dispatch(auditEntryModule.save(params, payload, id)),
  }
}

function calculateFormTotals(props) {
  const { auditsCache, initialValues, formValues } = props
  const { audit } = initialValues

  const auditResource = auditsCache[audit]
  const auditEntity = auditResource.entity || {}
  const auditItems = auditEntity.items || []

  const score = calculateScores(formValues, auditItems)
  const formScore = round(score.result * 100)

  return {
    formScore,
  }
}

function calculateScores(formValues, auditItems = []) {
  return reduce(
    auditItems,
    (accum, item) => {
      const { _id: itemId, required, scores, weight } = item

      accum.actual = accum.actual || 0
      accum.completed = accum.completed || 0
      accum.max = accum.max || 0
      accum.result = accum.result || 0
      accum.total = accum.total || 0

      const currentScoreId = get(formValues, `${itemId}.score`)
      if (!required && !currentScoreId) {
        return accum
      }

      const { value: scoreValue } = find(scores, ['_id', currentScoreId]) || {}

      const hasValue = !isUndefined(scoreValue)

      if ((required && !hasValue) || (hasValue && scoreValue >= 0)) {
        accum.total += 1
      }

      if (!isUndefined(scoreValue)) {
        accum.completed += 1

        if (scoreValue >= 0) {
          const scoreWeight = scoreValue * weight

          accum.actual += scoreWeight
          accum.max += weight
          accum.result = accum.actual / accum.max
        }
      }

      return accum
    },
    {}
  )
}

async function getReverseGeoCoded({ lat, lng }) {
  const response = await geocoder.reverse({ lat, lon: lng })

  const { address } = response

  const LABEL_PARTS = [
    ['name', 'houseNumber', 'viewpoint', 'road'],
    ['city', 'suburb', 'neighbourhood'],
    ['state', 'county', 'stateDistrict', 'country'],
  ]

  const allParts = reduce(
    LABEL_PARTS,
    (accum = [], fieldArr) => {
      const partField = find(fieldArr, field => address[field])
      const value = address[partField]
      if (value) accum.push(value)
      return accum
    },
    []
  )

  const longParts = allParts
  const reverse = longParts.join(', ')

  return reverse
}

function parseItemValues(formValues, template) {
  const { groups: groupValues } = formValues

  return map(template.items, item => {
    const { assets, comments, score: scoreId, followUpTriggered } =
      formValues[item._id] || {}
    const score = getScore(item.scores, scoreId)
    const skipped = get(groupValues, `[${item.group.id}].skipped`, false)

    return {
      ...item,
      group: {
        ...item.group,
        skipped,
      },
      assets,
      comments,
      followUpTriggered,
      score,
    }
  })
}

function getScore(scores, scoreId) {
  const scoreObj = find(scores, { _id: scoreId }) || {}

  return scoreObj.value
}

function mapStateToProps(state, props) {
  const { form } = props

  const areaSelectors = areaModule.selectors(state)(LIST_ID)
  const findEnclosing = areaSelectors.findEnclosing
  const formInitialValues = getFormInitialValues(form)(state)
  const formSyncErrors = getFormSyncErrors(form)(state)
  const formValues = getFormValues(form)(state)
  const userId = state.user.data._id

  const auditsCache = state.audits.cache
  const templatesCache = state.templates.cache

  return {
    auditsCache,
    findEnclosing,
    formInitialValues,
    formSyncErrors,
    formValues,
    sessionId: state.app.sessionId,
    userId,
    templatesCache,
  }
}
