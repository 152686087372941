import { getStatusDetails } from '@lighthouse/common'
import { getModule } from '@lighthouse/sdk'
import React from 'react'
import { chunk } from 'lodash'
import moment from 'moment-timezone'
import querystring from 'querystring'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useAsync } from 'react-use'
import emitter from 'utils/emitter'
import logger from 'utils/logger'

import colors from 'config/theme/colors'
import { Block, Flex, Span, Text } from 'components/common'
import Button from 'components/button'
import Icon from 'components/icon'
import UserFullName from 'components/user-full-name'

const issuesModule = getModule('issues')

interface FollowUp {
  answer: string
  issueId: string
  title: string
  questionLabel: string
  questionScoreText: string
}

interface FollowUpDetailed extends FollowUp {
  assignees: string[]
  status: string
  title: string
  updatedAt: string
}

type StatusCounts = Record<string, number>

interface FollowUpsListProps {
  followUps: FollowUp[]
}

export const FollowUpsList: React.FC<FollowUpsListProps> = (props) => {
  const { followUps } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const cachedIssues = useSelector(state => state.issues.cache)

  const params = new URLSearchParams(history.location.search)
  const followUpsExpanded = params.get('followUpsExpanded') === 'true'

  const toggleFollowUps = () => {
    params.set('followUpsExpanded', followUpsExpanded ? 'false' : 'true')
    history.push({
      search: params.toString(),
    })
  }

  const fetchIssues = useAsync(
    async () => {
      const batchSize = 10
      const batches = chunk(followUps, batchSize)
      for (const batch of batches) {
        try {
          await Promise.all(
            batch.map(followUp =>
              dispatch(issuesModule.findById(followUp.issueId))
            )
          )
        } catch (error) {
          emitter.emit('notification:add', {
            message: t('alert.message.fetchingIssuesError'),
            theme: 'alert',
            tags: ['errorFetchingIssues'],
            title: t('alert.title.fetchingIssuesError'),
          })
          logger.error('Error fetching issues', error)
        }
      }
    },
    [dispatch]
  )

  const followUpsWithDetails: FollowUpDetailed[] = followUps.reduce((acc, followUp) => {
    if (!followUp.issueId) return acc
    const issue = cachedIssues && cachedIssues[followUp.issueId]
    if (issue && issue.entity) {
      acc.push({
        assignees: issue.entity.assignees,
        status: issue.entity.status,
        title: issue.entity.title,
        updatedAt: issue.entity.updatedAt,
        ...followUp,
      })
    } else {
      acc.push(followUp)
    }
    return acc
  }, [])

  const statusCounts: StatusCounts = followUpsWithDetails.reduce((acc, followUp) => {
    const status = followUp.status
    if (!acc[status]) {
      acc[status] = 1
    } else {
      acc[status] += 1
    }
    return acc
  }, {})

  // NOTE Used to determine whether we have any issue information to show,
  // regardless of staleness
  const issuesLoaded = followUps.every(followUp => {
    return !!cachedIssues[followUp.issueId] && cachedIssues[followUp.issueId]?.entity?.status
  })

  return (
    <Block 
      backgroundColor="#f9f9f9" 
      border="1px solid #eee" 
      borderRadius={8} 
      marginBottom="3em"
    >

      <Flex 
        alignItems="center" 
        borderBottom="1px solid #eee"  
        cursor="pointer"
        padding="1.5em"
        onClick={toggleFollowUps}
      >
        <Flex flexDirection="column" width="100%">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex>
              <Icon
                name="alert-thick"
                backgroundColor="#FABE65"
                borderRadius="50%"
                color={colors.white}
                fontSize={18}
                height={34}
                lineHeight="31px"
                marginRight={10}
                textAlign="center"
                width={34}
              />
              <Flex flexDirection="column">
                <Text bold fontSize={14} margin="0 0 3px 0">
                  Follow-Up Issues
                </Text>
                <Flex flexWrap="wrap">
                {issuesLoaded
                  ? Object.keys(statusCounts).map(status => (
                      <Flex key={status} alignItems="center" marginRight={10}>
                        <Block width={8} height={8} backgroundColor={getStatusDetails(status).style.color} borderRadius="50%" marginRight={6} />
                        <Span fontSize={11} textTransform="uppercase">{statusCounts[status]} {getStatusDetails(status).text}</Span>
                      </Flex>
                    ))
                  : fetchIssues.loading
                  ? <Text margin="0" opacity={.75}>Loading...</Text>
                  : <Text margin="0">{followUps.length} Issues</Text>
                }
                </Flex>
              </Flex>
            </Flex>
            <Icon name={followUpsExpanded ? 'arrow-down' : 'arrow-left'} fontSize={16} marginLeft={10} />
          </Flex>
        </Flex>
      </Flex>
      {followUpsExpanded ? (
        <Block>
          {followUpsWithDetails.map(followUp => (
            <FollowUpItem key={followUp.issueId} {...followUp} />
          ))}
        </Block>
      ) : null}
    </Block>
  )
}

const FollowUpItem: React.FC<FollowUpDetailed> = (props) => {
  const {
    assignees = [],
    issueId,
    questionLabel = '',
    questionScoreText = '',
    status,
    title,
    updatedAt,
  } = props

  const history = useHistory()
  const timezone = useSelector(state => state.app.timezone)
  const issueCache = useSelector(state => state.issues.cache)
  const issueEntity = issueCache[issueId]?.entity

  const { style: statusStyle, text: statusText } = getStatusDetails(status)
  const updatedAtWithTimezone = moment(updatedAt).tz(timezone)
  const day = moment(updatedAtWithTimezone).format('HH:MM a')
  const date = moment(updatedAtWithTimezone).format('MMM DD')

  const handleOpenIssue = (): void => {
    const icon = 'issue'
    const lat = issueEntity?.gps?.geometry?.coordinates?.[1]
    const lng = issueEntity?.gps?.geometry?.coordinates?.[0]
    const resource = 'issues'

    const qs = querystring.stringify({
      icon,
      id: issueId,
      lat,
      lng,
      resource,
      showMarker: 'true',
      title,
    })

    history.push(`/?${qs}`)
  }

  return (
    <Block
      alignItems="space-between"
      borderBottom="1px solid #eee"
      display="flex"
      fontSize={12}
      padding="1.25em 1.5em"
      flexDirection="row"
    >
      <Block flexGrow={1} fontSize={14}>
        <Flex flexDirection="row">
          <Block flexGrow={1}>
            <Block marginBottom={10}>
              <Text fontSize={13} fontWeight={500} margin="0 0 2px">{title}</Text>
              <Text fontSize={12} small margin={0} opacity={.75}>Last Updated: {day} {date}</Text>
            </Block>
            <Text fontSize={13} fontWeight={500} margin="0 0 2px">Status</Text>
            <Text fontSize={12} paddingTop={0} color={statusStyle.color} margin={0} textTransform="uppercase">
              {statusText}
            </Text>
          </Block>
          {issueEntity ? (
            <Flex alignItems="flex-end" flexDirection="column" opacity={.75}>
              <Button
                onClick={handleOpenIssue}
                theme="simple"
              >
                <Span marginRight={8}>OPEN</Span>
                <Icon fontSize="22px" name="launch" />
              </Button>
            </Flex>
          ) : null}
        </Flex>
        <Block marginTop={10}>
          <Text fontSize={13} fontWeight={500} margin="0 0 2px">Assigned to</Text> 
          <Text fontSize={12} margin={0}>
            {assignees && assignees.length > 1 ? (
              `${assignees.length} users`
            ) : assignees && assignees.length > 0 ? (
              <UserFullName userId={assignees[0]} />
            ) : (
              'No assignee'
            )}
          </Text>
        </Block>
        <Block marginTop={10}>
          <Text fontSize={13} fontWeight={500} margin="0 0 2px">Question</Text>
          <Text fontSize={12} margin={0}>{questionLabel}</Text>
        </Block>
        <Block marginTop={10}>
          <Text fontSize={13} fontWeight={500} margin="0 0 2px">Answer</Text>
          <Text fontSize={12} margin={0}>{questionScoreText}</Text>
        </Block>
      </Block>
    </Block>
  )
}

