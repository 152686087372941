import { compose } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { WithPermissions } from '@lighthouse/react-components'
import { withRouter } from 'react-router-dom'
import geoWithin from '@turf/boolean-within'
import Promise from 'bluebird'
import queryString from 'query-string'
import React, { Component } from 'react'
import Radium from 'radium'
import { TOOLTIPS } from 'config/constants'

import {
  getErrorCount,
  handleFormError,
  handleResponse,
} from 'components/form/helpers'

import Confirmable from 'components/confirmable'
import { Crud } from 'components/controls'
import FlagsHOC from 'components/flags/hoc'
import PluginsHOC from 'components/plugins'
import emitter from '../../../../../../../../../utils/emitter'

import { capitalize, chain, find, get, includes, noop, without } from 'lodash'

import { getModule, validation } from '@lighthouse/sdk'

import colors from 'config/theme/colors'

import Alert from 'components/alert'
import Button from 'components/button'
import ButtonGroup from 'components/button-group'
import ButtonIcon from 'components/button-icon'
import Icon from 'components/icon'

import { Block, ListItem, UnorderedList } from 'components/common'

import {
  BasicForm,
  FieldGroup,
  FieldSet,
  InputCountrySelect,
  InputTimeZoneSelect,
  InputText,
  InputSelect,
} from 'components/form'

import * as logger from 'utils/logger'

import * as constants from '../../../../../../../lib/constants'
import ControlWrapper from '../../../../control-wrapper'
import FieldWrapper from '../../../../field-wrapper'
import TitleDivider from '../../../../title-divider'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'

const areasModule = getModule('areas')
const signalModule = getModule('signals')
const geoModule = getModule('geo')

const typeOptions = [
  {
    label: 'Location',
    value: constants.TYPE_LOCATION,
  },
  {
    label: 'Geofence',
    value: constants.TYPE_GEOFENCE,
  },
  {
    label: 'Building',
    value: constants.TYPE_BUILDING,
  },
  {
    label: 'Floor',
    value: constants.TYPE_FLOOR,
  },
  {
    label: 'Room',
    value: constants.TYPE_ROOM,
  },
  {
    label: 'Corridor',
    value: constants.TYPE_CORRIDOR,
  },
  {
    label: 'Wall',
    value: constants.TYPE_WALL,
  },
  {
    label: 'Object',
    value: constants.TYPE_OBJECT,
  },
  {
    label: 'Transition',
    value: constants.TYPE_TRANSITION,
  },
]

const SUPPORTED_EDITABLE_TYPES = [constants.TYPE_LOCATION]

const SUPPORTED_ADDRESS_TYPES = [
  constants.TYPE_LOCATION,
  constants.TYPE_GEOFENCE,
  constants.TYPE_BUILDING,
]

const handleAreaResponse = handleResponse.bind(null, 'area')
const isRequiredFn = validation.isRequired()
const isRequired = value =>
  isRequiredFn(value) ? i18next.t('validation.requiredField') : undefined

class AreaDetailsForm extends Component {
  constructor(props) {
    super(props)

    this.localDeleteArea = this.localDeleteArea.bind(this)
    this.deleteAudit = this.deleteAudit.bind(this)
    this.deleteEntry = this.deleteEntry.bind(this)
    this.deleteIssue = this.deleteIssue.bind(this)
    this.deleteTask = this.deleteTask.bind(this)
    this.deleteLocationGroup = this.deleteLocationGroup.bind(this)
    this.getAuditListItem = this.getAuditListItem.bind(this)
    this.getEntryListItem = this.getEntryListItem.bind(this)
    this.getIssueListItem = this.getIssueListItem.bind(this)
    this.getTaskListItem = this.getTaskListItem.bind(this)
    this.getLocationGroupItem = this.getLocationGroupItem.bind(this)
    this.saveForm = this.saveForm.bind(this)
    this.updateFloors = this.updateFloors.bind(this)
    this.state = {
      attemptedToDelete: false,
    }
  }

  saveForm(formValues) {
    const { areaId, saveArea, updateAreaView } = this.props

    return saveArea(areaId, formValues)
      .then(handleAreaResponse)
      .then(() =>
        updateAreaView({
          isEditing: false,
        })
      )
      .catch(handleFormError)
  }

  render() {
    const {
      areaId,
      areaCache,
      building,
      dirty,
      error,
      errorCount,
      flags,
      handleSubmit,
      hasModulePermission,
      history,
      initialValues,
      invalid,
      isEditing,
      isBuilding,
      isLighthouseAdmin,
      plugins,
      reset,
      submitFailed,
      submitting,
      updateAreaView,
      t,
      modal,
    } = this.props

    const { entries = [], floors, name, type } = initialValues

    const hasContentEntries = entries.length
    const hasContentFlag = !!flags.content
    const hasGraphFlag = !!flags.graph
    const hasLocationCreatePermission = hasModulePermission(
      'location',
      'create'
    )
    const hasFloors = floors.length

    const isAddressType = includes(SUPPORTED_ADDRESS_TYPES, type)
    const isEditableType = includes(SUPPORTED_EDITABLE_TYPES, type)
    const isLocationType = type === constants.TYPE_LOCATION
    const isFloorType = type === constants.TYPE_FLOOR
    const isBuildingType = type === constants.TYPE_BUILDING
    const readOnly = !isEditing

    const winTeamEnabled = get(plugins, 'winteam.enabled')
    const dayforceEnabled = get(plugins, 'dayforce.enabled')
    const timegateEnabled = get(plugins, 'timegate.enabled')

    const buttonEditBoundaries =
      isEditableType && (hasLocationCreatePermission || isLighthouseAdmin) ? (
        <Button
          key="buttonEditBoundaries"
          onClick={() =>
            history.push({
              search: `?${queryString.stringify({
                action: 'edit-boundaries',
                id: areaId,
                resource: 'area',
              })}`,
            })
          }
        >
          <Icon name="geo-fence" fontSize={24} marginRight={5} padding={0} />
          {t('button.editBoundaries')}
        </Button>
      ) : null

    const buttonDeviceRelationships =
      isEditableType &&
      ((hasGraphFlag && hasLocationCreatePermission) || isLighthouseAdmin) ? (
        <Button
          key="buttonDeviceRelationships"
          onClick={() =>
            history.push({
              search: `?${queryString.stringify({
                action: 'device-relationships',
                id: areaId,
                resource: 'area',
              })}`,
            })
          }
        >
          <Icon name="geo-fence" fontSize={24} marginRight={5} padding={0} />
          {t('button.deviceRel')}
        </Button>
      ) : null

    const buttonAddEntry = (
      <Button
        key="buttonAddEntry"
        onClick={() =>
          updateAreaView({
            selectedId: null,
            isNewEntryMode: true,
          })
        }
        marginLeft="auto"
        marginRight={0}
        padding={0}
        theme="transparent"
      >
        <Icon name="plus" fontSize={36} />
      </Button>
    )

    const buttonAddLocationGroup = (
      <Button
        key="buttonAddLocationGroup"
        onClick={() =>
          updateAreaView({
            selectedId: null,
            isAddLocationGroupMode: true,
          })
        }
        marginLeft="auto"
        marginRight={0}
        padding={0}
        theme="transparent"
      >
        <Icon name="plus" fontSize={36} />
      </Button>
    )

    const buttonAddTask = (
      <Button
        key="buttonAddTask"
        onClick={() =>
          updateAreaView({
            selectedId: null,
            isAddTaskTemplateMode: true,
          })
        }
        marginLeft="auto"
        marginRight={0}
        padding={0}
        theme="transparent"
      >
        <Icon name="plus" fontSize={36} />
      </Button>
    )

    const buttonAddAudit = (
      <Button
        key="buttonAddAudit"
        onClick={() =>
          updateAreaView({
            selectedId: null,
            isAddAuditTemplateMode: true,
          })
        }
        marginLeft="auto"
        marginRight={0}
        padding={0}
        theme="transparent"
      >
        <Icon name="plus" fontSize={36} />
      </Button>
    )

    const buttonNewFloor = (
      <Button
        key="buttonNewFloor"
        onClick={() =>
          updateAreaView({
            // There must be a selected floor,
            // so a fallback floor is provided
            selectedFloor:
              areaCache[areaId].entity.floors.find(
                floor => building.floor === floor.level
              ) || areaCache[areaId].entity.floors[0],
            isAddingFloorMode: true,
            isEditing: false,
          })
        }
        marginLeft="auto"
        marginRight={0}
        padding={0}
        theme="transparent"
      >
        <Icon name="plus" fontSize={36} />
      </Button>
    )

    const buttonAddIssue = (
      <Button
        key="buttonAddIssue"
        onClick={() =>
          updateAreaView({
            selectedId: null,
            isAddIssueTemplateMode: true,
          })
        }
        marginLeft="auto"
        marginRight={0}
        padding={0}
        theme="transparent"
      >
        <Icon name="plus" fontSize={36} />
      </Button>
    )

    const contentEntryList = hasContentFlag ? this.getContentEntries() : null
    const locationGroupList = this.getLocationGroups()

    const issueList = this.getIssues()
    const taskList = this.getTasks()
    const auditsList = this.getAudits()

    const issues = get(issueList, 'props.children')
    const audits = get(auditsList, 'props.children')
    const tasks = get(taskList, 'props.children')
    const locationGroups = get(locationGroupList, 'props.children')

    const hasIssues = issues.length
    const hasTasks = tasks.length
    const hasAudits = audits.length
    const hasLocationGroups = locationGroups && locationGroups.length > 0

    const jobNumberTooltip = readOnly
      ? null
      : [t(TOOLTIPS.jobNumber.titleT), t(TOOLTIPS.jobNumber.messageT)]

    const timezoneTooltip = readOnly
      ? null
      : [t(TOOLTIPS.timezone.titleT), t(TOOLTIPS.timezone.messageT)]

    return (
      <Block>
        <BasicForm noValidate>
          <FieldWrapper>
            {submitFailed && <Alert type="error" messages={[error]} />}
            <TitleDivider title={`${capitalize(type)} Details`} />
            <FieldGroup>
              <FieldSet>
                <Field
                  component={InputText}
                  dataTestId="location-name"
                  label="Name"
                  name="name"
                  readOnly={readOnly}
                  required={isLocationType}
                  small
                  validate={isLocationType ? [isRequired] : []}
                />
                <Field
                  component={InputSelect}
                  label="Type"
                  disabled={isLocationType}
                  name="type"
                  options={typeOptions}
                  readOnly={readOnly}
                  required
                  small
                  validate={[isRequired]}
                />
                {flags.references && (
                  <Field
                    component={InputText}
                    label={t('labelReference')}
                    placeholder={t('placeholder.reference')}
                    name="reference"
                    readOnly={readOnly}
                    small
                    tooltip={[
                      t('tooltip.reference.title'),
                      t('tooltip.reference.message'),
                    ]}
                  />
                )}
                {isAddressType && (
                  <Block>
                    <Field
                      component={InputText}
                      label="Street"
                      name="address.street"
                      readOnly={readOnly}
                      small
                    />
                    <Field
                      component={InputText}
                      label="City"
                      name="address.city"
                      readOnly={readOnly}
                      small
                    />
                    <Field
                      component={InputText}
                      label="State"
                      name="address.state"
                      readOnly={readOnly}
                      small
                    />
                    <Field
                      component={InputText}
                      label="Postal Code"
                      name="address.postalCode"
                      readOnly={readOnly}
                      small
                    />
                    <Field
                      component={InputCountrySelect}
                      label="Country"
                      name="address.country"
                      readOnly={readOnly}
                      small
                    />
                    <Field
                      component={InputTimeZoneSelect}
                      label="Timezone"
                      name="timezone"
                      readOnly={readOnly}
                      required={isLocationType}
                      small
                      tooltip={timezoneTooltip}
                      validate={isLocationType ? [isRequired] : null}
                    />
                    {winTeamEnabled && (
                      <Field
                        component={InputText}
                        label="Job Number"
                        name="plugins.winteam.options.jobNumber"
                        readOnly={readOnly}
                        tooltip={jobNumberTooltip}
                        small
                      />
                    )}
                    {dayforceEnabled && (
                      <>
                        <TitleDivider title={t('labelDayforce')} />
                        <FieldGroup>
                          <FieldSet>
                            <Field
                              component={InputText}
                              label={t('labelDayforceLocationXRefCode')}
                              name="plugins.dayforce.options.locationXRefCode"
                              readOnly={readOnly}
                              small
                            />
                            <Field
                              component={InputText}
                              label="Location Clock Transfer Code"
                              name="plugins.dayforce.options.locationClockTransferCode"
                              readOnly={readOnly}
                              small
                            />
                          </FieldSet>
                        </FieldGroup>
                      </>
                    )}
                    {timegateEnabled && isLocationType && (
                      <>
                        <TitleDivider title={t('labelTimegate')} />
                        <FieldGroup>
                          <FieldSet>
                            <Field
                              component={InputText}
                              label={t('labelTimegateSIN')}
                              name="plugins.timegate.options.SiteSIN"
                              readOnly={readOnly}
                              small
                            />
                          </FieldSet>
                        </FieldGroup>
                      </>
                    )}
                  </Block>
                )}
              </FieldSet>
            </FieldGroup>
            {isLocationType && (hasLocationGroups || isEditing) && (
              <Block>
                <TitleDivider title="Location Groups">
                  {isEditing && buttonAddLocationGroup}
                </TitleDivider>
                {locationGroupList}
              </Block>
            )}
            {isLocationType &&
              (hasContentEntries || isEditing) &&
              (hasContentFlag || isLighthouseAdmin) && (
                <Block>
                  <TitleDivider title="Assigned content">
                    {isEditing && buttonAddEntry}
                  </TitleDivider>
                  {contentEntryList}
                </Block>
              )}
            {isLocationType && (hasIssues || isEditing) && (
              <Block>
                <TitleDivider title="Assigned issue forms">
                  {isEditing && buttonAddIssue}
                </TitleDivider>
                {issueList}
              </Block>
            )}
            {isLocationType && (hasTasks || isEditing) && (
              <Block>
                <TitleDivider title="Assigned task forms">
                  {isEditing && buttonAddTask}
                </TitleDivider>
                {taskList}
              </Block>
            )}
            {(hasAudits || isEditing) && (
              <Block>
                <TitleDivider title="Assigned audits forms">
                  {isEditing && buttonAddAudit}
                </TitleDivider>
                {auditsList}
              </Block>
            )}
            {isLighthouseAdmin && isBuilding && (hasFloors || isEditing) && (
              <Block>
                <TitleDivider title={t('labelFloorsTitle')}>
                  {buttonNewFloor}
                </TitleDivider>
                {areaCache[areaId].entity.floors.map((floor, index) => {
                  const listItemRadiumStyles = isEditing
                    ? null
                    : {
                        ':hover': {
                          backgroundColor: colors.blue.lightest,
                          cursor: 'pointer',
                        },
                      }
                  return (
                    <ListItem
                      alignItems="center"
                      borderBottom={`1px solid ${colors.gray.lightest}`}
                      display="flex"
                      fontSize={12}
                      justifyContent="space-between"
                      key={`${index}-floor`}
                      marginLeft={-15}
                      marginRight={-15}
                      onClick={() =>
                        !isEditing &&
                        updateAreaView({
                          selectedFloor: floor,
                          isEditFloorMode: true,
                          isEditing: false,
                        })
                      }
                      paddingBottom={12}
                      paddingLeft={15}
                      paddingRight={15}
                      paddingTop={12}
                      radiumStyles={listItemRadiumStyles}
                    >
                      <span>
                        {floor.level} - {floor.labelShort} - {floor.label}
                      </span>
                      {isEditing ? (
                        <ButtonIcon
                          backgroundColor={colors.red.normal}
                          onClick={() => {
                            modal.show({
                              deleteModalMessage: t(
                                'modal.delete.floor.message'
                              ),
                              deleteModalTitle: t('modal.delete.floor.title'),
                              handleDelete: () => {
                                this.updateFloors({
                                  floors,
                                  floorToRemove: floor,
                                })
                              },
                            })
                          }}
                          marginRight={6}
                        >
                          <Icon
                            name="neutral"
                            color={colors.white}
                            fontSize={12}
                            fontWeight={600}
                            lineHeight={1}
                          />
                        </ButtonIcon>
                      ) : (
                        <Icon name="arrow-right2" />
                      )}
                    </ListItem>
                  )
                })}
              </Block>
            )}
          </FieldWrapper>
          <ControlWrapper>
            <ButtonGroup>
              <Crud
                canDelete={hasLocationCreatePermission || isLighthouseAdmin}
                canEdit={hasLocationCreatePermission || isLighthouseAdmin}
                deleteModalMessage={
                  isLocationType
                    ? t('modal.delete.location.message')
                    : isFloorType
                    ? t('modal.delete.floor.message')
                    : isBuildingType
                    ? t('modal.delete.building.message')
                    : t('modal.delete.area.message')
                }
                deleteModalTitle={
                  isLocationType
                    ? t('modal.delete.location.title')
                    : isFloorType
                    ? t('modal.delete.floor.title')
                    : isBuildingType
                    ? t('modal.delete.building.title')
                    : t('modal.delete.area.title')
                }
                dirty={dirty}
                errorCount={errorCount}
                handleCancel={() =>
                  updateAreaView({
                    isEditing: !isEditing,
                  })
                }
                handleDeleteAttempt={value =>
                  this.setState({ attemptedToDelete: value })
                }
                handleDelete={this.localDeleteArea}
                handleEdit={() =>
                  updateAreaView({
                    isEditing: !isEditing,
                  })
                }
                handleSave={handleSubmit(this.saveForm)}
                id={areaId}
                invalid={invalid}
                isEditing={isEditing}
                showConfirmMessageAbove={isLocationType}
                name={name}
                small
                submitting={submitting}
                reset={reset}
              />
              {!isEditing && buttonDeviceRelationships}
              {!isEditing && buttonEditBoundaries}
            </ButtonGroup>
          </ControlWrapper>
        </BasicForm>
      </Block>
    )
  }

  localDeleteArea() {
    const {
      areaId,
      areaCache,
      areaTasks,
      building,
      deleteArea,
      fetchArea,
      history,
      removeAreaFromCache,
      removeSignalFromCache,
      setBuilding,
      setLocation,
      updateReferences,
    } = this.props

    const deletingArea = areaCache[areaId]
    const isFloor = get(deletingArea, 'entity.type') === constants.TYPE_FLOOR

    return (
      Promise.resolve()
        // NOTE delete references before removing task as API will 404 without task
        .then(() => updateReferences(areaId, 'tasks', areaTasks, true))
        .then(() => deleteArea(areaId))
        .then(res => {
          const data = get(res, 'data')
          const areas = get(data, 'areas', [])
          const signals = get(data, 'signals', [])

          signals.forEach(removeSignalFromCache)
          areas.forEach(removeAreaFromCache)
          building.id && removeAreaFromCache(building.id)
        })
        .then(() => {
          if (isFloor) {
            setLocation({})
            setBuilding({})
          }
        })
        .then(() => fetchArea(areaId))
        .then(() => history.push({ search: '' }))
        .catch(handleError('area'))
    )
  }

  updateFloors({ floorToRemove }) {
    const {
      areaId,
      areaCache,
      deleteArea,
      fetchArea,
      history,
      removeAreaFromCache,
      removeSignalFromCache,
      setBuilding,
      setLocation,
      updateAreaView,
    } = this.props

    const floorLevelToRemove = floorToRemove.level

    if (!floorLevelToRemove) {
      return
    }

    const deletingArea = areaCache[areaId]
    const deletedAreaGeometry = get(deletingArea.entity, 'geometry')

    const points = []

    const floor = find(areaCache, resource => {
      if (resource.id === deletingArea.id) return false

      const resourceType = get(resource.entity, 'type')
      const isFloor = resourceType === 'floor'
      const isPoint = resourceType === 'point'

      const resourceFloorsRef = get(resource.entity, 'floorsRef')
      const floorLevelMatches = resourceFloorsRef.includes(floorLevelToRemove)

      try {
        const resourceGeometry = get(resource.entity, 'geometry')
        const within = geoWithin(resourceGeometry, deletedAreaGeometry)

        if (within && isPoint) {
          points.push(resource.id)
        }

        return isFloor && floorLevelMatches && within
      } catch (error) {
        logger.error('Delete Area', error)
        return false
      }
    })

    const floorId = floor && floor.id
    if (!floorId) {
      return
    }

    updateAreaView({ isLoading: true })

    return deleteArea(floorId)
      .then(res => {
        const data = get(res, 'data', {})
        const signals = get(data, 'signals', [])
        signals.forEach(removeSignalFromCache)
        points.forEach(removeAreaFromCache)

        setLocation({})
        setBuilding({})
        removeAreaFromCache(areaId)
        removeAreaFromCache(floorId)
      })
      .then(() => history.push({ search: '' }))
      .then(() => fetchArea(floorId))
      .catch(handleError('area'))
      .finally(() => {
        updateAreaView({
          isLoading: false,
        })
      })
  }

  deleteEntry(id) {
    const { areaId, areaEntries, saveArea, updateAreaView } = this.props

    const entries = without(areaEntries, id)

    updateAreaView({ isLoading: true })

    return saveArea(areaId, { entries })
      .catch(handleError('entry'))
      .finally(() => updateAreaView({ isLoading: false }))
  }

  deleteTask(id) {
    const { areaId, updateReferences, updateAreaView } = this.props

    updateAreaView({ isLoading: true })

    return updateReferences(areaId, 'tasks', [id], true)
      .catch(handleError('task'))
      .finally(() => updateAreaView({ isLoading: false }))
  }

  deleteAudit(id) {
    const { areaId, updateReferences, updateAreaView } = this.props

    updateAreaView({ isLoading: true })

    return updateReferences(areaId, 'audits', [id], true)
      .catch(handleError('audit'))
      .finally(() => updateAreaView({ isLoading: false }))
  }

  deleteIssue(id) {
    const { areaId, updateReferences, updateAreaView } = this.props

    updateAreaView({ isLoading: true })

    return updateReferences(areaId, 'issues', [id], true)
      .catch(handleError('issue'))
      .finally(() => updateAreaView({ isLoading: false }))
  }

  deleteLocationGroup(id) {
    const {
      areaId,
      removeLocationFromGroup,
      updateAreaView,
      findArea,
    } = this.props

    updateAreaView({ isLoading: true })

    return removeLocationFromGroup(id, areaId)
      .catch(e => {
        emitter.emit('notification:add', {
          message: e.message,
          theme: 'alert',
          title: 'Remove Group Error',
        })
        handleError('groups')
      })
      .then(() => findArea(areaId).catch(handleError('groups')))
      .finally(e => {
        updateAreaView({ isLoading: false })
      })
  }

  getContentEntries() {
    const { areaEntries, entryCache, flags } = this.props

    const listItems = chain(areaEntries)
      .map(entryId => entryCache[entryId])
      .compact()
      .filter(getValidListItem)
      .sortBy(['entity.name'])
      .map(this.getEntryListItem)
      .value()

    return (
      <UnorderedList margin={0} padding={0}>
        {listItems}
      </UnorderedList>
    )
  }

  getEntryListItem(entry, index) {
    const { isEditing, updateAreaView } = this.props

    const entity = entry.entity || {}
    const id = entity._id
    const name = entity.name

    const onClickHandler = isEditing
      ? noop
      : () =>
          updateAreaView({
            selectedId: id,
          })

    const listItemRadiumStyles = isEditing
      ? null
      : {
          ':hover': {
            backgroundColor: colors.blue.lightest,
            cursor: 'pointer',
          },
        }

    return (
      <ListItem
        alignItems="center"
        borderBottom={`1px solid ${colors.gray.lightest}`}
        display="flex"
        fontSize={12}
        justifyContent="space-between"
        key={`${index}-entry`}
        marginLeft={-15}
        marginRight={-15}
        onClick={onClickHandler}
        paddingBottom={12}
        paddingLeft={15}
        paddingRight={15}
        paddingTop={12}
        radiumStyles={listItemRadiumStyles}
      >
        <span>{name}</span>
        {isEditing && (
          <Confirmable onConfirmed={() => this.deleteEntry(id)} size="small">
            {({ handleConfirm, isConfirming, confirmEl }) =>
              isConfirming ? (
                confirmEl
              ) : (
                <ButtonIcon
                  backgroundColor={colors.red.normal}
                  onClick={handleConfirm}
                  marginRight={6}
                >
                  <Icon
                    name="neutral"
                    color={colors.white}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={1}
                  />
                </ButtonIcon>
              )
            }
          </Confirmable>
        )}
        {!isEditing && <Icon name="arrow-right2" />}
      </ListItem>
    )
  }

  getLocationGroups() {
    const { groupsAssignedToLocation, locationGroupsCache } = this.props

    const listItems = groupsAssignedToLocation
      ? groupsAssignedToLocation.map(locationGroupId => {
          const locationGroup =
            find(locationGroupsCache, ['id', locationGroupId]) || {}
          return this.getLocationGroupItem(locationGroup)
        })
      : []

    return (
      <UnorderedList margin={0} padding={0}>
        {listItems}
      </UnorderedList>
    )
  }

  getTasks() {
    const { areaTasks, templateCache } = this.props

    const listItems = chain(areaTasks)
      .map(taskId => templateCache[taskId])
      .compact()
      .filter(getValidListItem)
      .sortBy(['entity.name'])
      .map(this.getTaskListItem)
      .value()

    return (
      <UnorderedList margin={0} padding={0}>
        {listItems}
      </UnorderedList>
    )
  }

  getAudits() {
    const { areaAudits, auditCache } = this.props
    const listItems = chain(areaAudits)
      .map(auditId => auditCache[auditId])
      .compact()
      .filter(getValidListItem)
      .sortBy(['entity.name'])
      .map(this.getAuditListItem)
      .value()
    return (
      <UnorderedList margin={0} padding={0}>
        {listItems}
      </UnorderedList>
    )
  }

  getIssues() {
    const { areaIssues, templateCache } = this.props

    const listItems = chain(areaIssues)
      .map(issueId => templateCache[issueId])
      .compact()
      .filter(getValidListItem)
      .sortBy(['entity.name'])
      .map(this.getIssueListItem)
      .value()

    return (
      <UnorderedList margin={0} padding={0}>
        {listItems}
      </UnorderedList>
    )
  }

  getTaskListItem(entry, index) {
    const { isEditing } = this.props

    const entity = entry.entity || {}
    const id = entity._id
    const name = entity.name

    return (
      <ListItem
        alignItems="center"
        borderBottom={`1px solid ${colors.gray.lightest}`}
        color={colors.gray.light}
        display="flex"
        fontSize={12}
        justifyContent="space-between"
        key={`${index}-task`}
        marginLeft={-15}
        marginRight={-15}
        paddingBottom={12}
        paddingLeft={15}
        paddingRight={15}
        paddingTop={12}
      >
        <span>{name}</span>
        {isEditing && (
          <Confirmable onConfirmed={() => this.deleteTask(id)} size="small">
            {({ handleConfirm, isConfirming, confirmEl }) =>
              isConfirming ? (
                confirmEl
              ) : (
                <ButtonIcon
                  backgroundColor={colors.red.normal}
                  onClick={handleConfirm}
                  marginRight={6}
                >
                  <Icon
                    name="neutral"
                    color={colors.white}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={1}
                  />
                </ButtonIcon>
              )
            }
          </Confirmable>
        )}
      </ListItem>
    )
  }

  getAuditListItem(entry, index) {
    const { isEditing } = this.props
    const entity = entry.entity || {}
    const id = entity._id
    const name = entity.title

    return (
      <ListItem
        alignItems="center"
        borderBottom={`1px solid ${colors.gray.lightest}`}
        color={colors.gray.light}
        display="flex"
        fontSize={12}
        justifyContent="space-between"
        key={`${index}-task`}
        marginLeft={-15}
        marginRight={-15}
        paddingBottom={12}
        paddingLeft={15}
        paddingRight={15}
        paddingTop={12}
      >
        <span>{name}</span>
        {isEditing && (
          <Confirmable onConfirmed={() => this.deleteAudit(id)} size="small">
            {({ handleConfirm, isConfirming, confirmEl }) =>
              isConfirming ? (
                confirmEl
              ) : (
                <ButtonIcon
                  backgroundColor={colors.red.normal}
                  onClick={handleConfirm}
                  marginRight={6}
                >
                  <Icon
                    name="neutral"
                    color={colors.white}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={1}
                  />
                </ButtonIcon>
              )
            }
          </Confirmable>
        )}
      </ListItem>
    )
  }

  getIssueListItem(entry, index) {
    const { isEditing } = this.props

    const entity = entry.entity || {}
    const id = entity._id
    const name = entity.name

    return (
      <ListItem
        key={`${index}-template`}
        alignItems="center"
        borderBottom={`1px solid ${colors.gray.lightest}`}
        color={colors.gray.light}
        display="flex"
        fontSize={12}
        justifyContent="space-between"
        marginLeft={-15}
        marginRight={-15}
        paddingBottom={12}
        paddingLeft={15}
        paddingRight={15}
        paddingTop={12}
      >
        <span>{name}</span>
        {isEditing && (
          <Confirmable onConfirmed={() => this.deleteIssue(id)} size="small">
            {({ handleConfirm, isConfirming, confirmEl }) =>
              isConfirming ? (
                confirmEl
              ) : (
                <ButtonIcon
                  backgroundColor={colors.red.normal}
                  onClick={handleConfirm}
                  marginRight={6}
                >
                  <Icon
                    name="neutral"
                    color={colors.white}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={1}
                  />
                </ButtonIcon>
              )
            }
          </Confirmable>
        )}
      </ListItem>
    )
  }

  getLocationGroupItem(entry, index) {
    const { isEditing, t } = this.props

    const entity = entry.entity || {}
    const id = entity._id
    const name = entity.name || t('labelUnknownLocationGroup')

    return (
      <ListItem
        key={`${index}-locationGroup`}
        alignItems="center"
        borderBottom={`1px solid ${colors.gray.lightest}`}
        color={colors.gray.light}
        display="flex"
        fontSize={12}
        justifyContent="space-between"
        marginLeft={-15}
        marginRight={-15}
        paddingBottom={12}
        paddingLeft={15}
        paddingRight={15}
        paddingTop={12}
      >
        <span>{name}</span>
        {isEditing && (
          <Confirmable
            onConfirmed={() => this.deleteLocationGroup(id)}
            size="small"
          >
            {({ handleConfirm, isConfirming, confirmEl }) =>
              isConfirming ? (
                confirmEl
              ) : (
                <ButtonIcon
                  backgroundColor={colors.red.normal}
                  onClick={handleConfirm}
                  marginRight={6}
                >
                  <Icon
                    name="neutral"
                    color={colors.white}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={1}
                  />
                </ButtonIcon>
              )
            }
          </Confirmable>
        )}
      </ListItem>
    )
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  WithPermissions,
  FlagsHOC,
  PluginsHOC,
  reduxForm(),
  withTranslation(),
  Radium,
  withRouter
)(AreaDetailsForm)

function mapStateToProps(state, props) {
  const { form } = props
  const areaSelectors = areasModule.selectors(state)()
  const errors = get(state, `form.${form}.syncErrors`, {})
  const errorCount = getErrorCount(errors)
  const locationGroupsCache = areaSelectors.byType('location-group')

  return {
    areaCache: state.areas.cache,
    building: state.geo.building,
    entryCache: state.content.entries.cache,
    errorCount,
    taskCache: state.tasks.cache,
    templateCache: state.templates.cache,
    auditCache: state.audits.cache,
    locationGroupsCache,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    findArea: (id, params) => dispatch(areasModule.findById(id, params)),
    deleteArea: (id, params) => dispatch(areasModule.remove(id, params)),
    removeAreaFromCache: id => dispatch(areasModule.removeFromCache(id)),
    removeSignalFromCache: id => dispatch(signalModule.removeFromCache(id)),
    saveArea: (id, payload) => dispatch(areasModule.save({}, payload, id)),
    setBuilding: opts => dispatch(geoModule.setBuilding(opts)),
    setLocation: opts => dispatch(geoModule.setLocation(opts)),
    updateReferences: (areaId, collection, ids, shouldRemove) =>
      dispatch(
        areasModule.updateReferences(areaId, collection, ids, shouldRemove)
      ),
    fetchArea: _id => dispatch(areasModule.query('all', { _id })),
    removeLocationFromGroup: (groupId, locationId) =>
      dispatch(areasModule.removeLocationsFromGroup(groupId, [locationId])),
  }
}

function getValidListItem(entry = {}) {
  if (!entry || (entry.error && entry.error.code === 404)) return false

  return true
}

function handleError(entity) {
  return (error = {}) => {
    const message = error.message || `Error deleting ${entity} for area`
    console.error(message)
  }
}
