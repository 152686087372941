import React from 'react'

import { Flex, Text } from 'components/common'
import colors from 'config/theme/colors'

export default function TitleDivider(props) {
  return (
    <Flex
      alignItems="center"
      backgroundColor={colors.gray.white}
      borderBottom={`1px solid ${colors.gray.lightest}`}
      borderTop={`1px solid ${colors.gray.lightest}`}
      marginLeft={-15}
      marginRight={-15}
      paddingBottom={15}
      paddingLeft={15}
      paddingRight={15}
      paddingTop={15}
      {...props.style}
    >
      <Text small>{props.title}</Text>
      {props.children}
    </Flex>
  )
}
